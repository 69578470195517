import "../../style.scss";

import { Container, Nav, Navbar } from "react-bootstrap";

import Hero from "./hero";
import { Link } from "gatsby";
import Logo from "../../content/assets/logo_no_bg.png";
import React from "react";
import { SocialIcon } from "react-social-icons";
import classNames from "classnames";
import styled from "styled-components";
import { useShoppingCart } from "use-shopping-cart";

export default function Layout(props) {
    const { location, children, layoutClass } = props;
    const { cartCount } = useShoppingCart();

    function Header() {
        return (
            <>
                <div className="main-nav d-none d-md-flex">
                    <div className="col-12 col-md-2 p-0 header-link-container align-content-middle">
                        <Link to={"/"} className="header-link">
                            <img src={Logo} />
                        </Link>
                    </div>
                    <nav className="col-12 col-md-10 mb-4 mt-0  d-none d-md-flex mt-5 p-md-0 p-sm-4 pt-4">
                        <Link
                            className="header-link nav-link"
                            to="/"
                            disabled={location.pathname === "/"}
                        >
                            Home
                        </Link>
                        &nbsp;|&nbsp;
                        <Link
                            className="header-link nav-link"
                            to="/store/"
                            disabled={location.pathname === "/store/"}
                        >
                            Store
                        </Link>
                        &nbsp;|&nbsp;
                        <Link
                            className="header-link nav-link"
                            to="/about/"
                            disabled={location.pathname === "/about/"}
                        >
                            About
                        </Link>
                        &nbsp;|&nbsp;
                        <Link
                            to="/blog/"
                            className="header-link nav-link"
                            disabled={location.pathname === "/blog/"}
                        >
                            Blog
                        </Link>
                        &nbsp;|&nbsp;
                        <Link
                            className="header-link nav-link"
                            to="/contact/"
                            disabled={location.pathname === "/contact/"}
                        >
                            Contact
                        </Link>
                        {cartCount > 0 && (
                            <Link
                                className="header-link nav-link"
                                to="/cart/"
                                disabled={location.pathname === "/cart/"}
                            >
                                Cart
                            </Link>
                        )}
                    </nav>
                </div>
                <Navbar
                    bg="bark"
                    expand="sm"
                    collapseOnSelect
                    className="mobile-navbar d-flex d-md-none"
                >
                    <Container fluid>
                        <Navbar.Brand href="/">
                            <img src={Logo} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Link
                                    className="header-link nav-link"
                                    to="/"
                                    disabled={location.pathname === "/"}
                                >
                                    Home
                                </Link>
                                <Link
                                    className="header-link nav-link"
                                    to="/store/"
                                    disabled={location.pathname === "/store/"}
                                >
                                    Store
                                </Link>
                                <Link
                                    className="header-link nav-link"
                                    to="/about/"
                                    disabled={location.pathname === "/about/"}
                                >
                                    About
                                </Link>
                                <Link
                                    to="/blog/"
                                    className="header-link nav-link"
                                    disabled={location.pathname === "/blog/"}
                                >
                                    Blog
                                </Link>
                                <Link
                                    className="header-link nav-link"
                                    to="/contact/"
                                    disabled={location.pathname === "/contact/"}
                                >
                                    Contact
                                </Link>
                                {cartCount > 0 && (
                                    <Link
                                        className="header-link nav-link"
                                        to="/cart/"
                                        disabled={
                                            location.pathname === "/cart/"
                                        }
                                    >
                                        Cart
                                    </Link>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
        );
    }

    return (
        <>
            <HeaderContainer className="row mb-0 px-2 px-md-5 d-flex">
                <Header />
            </HeaderContainer>
            {location.pathname === "/" && <Hero />}
            <Container>
                <LayoutContainer>
                    <MainContainer className={classNames(layoutClass, "row")}>
                        <main className="px-2 px-lg-0">{children}</main>
                    </MainContainer>
                </LayoutContainer>
                <Footer className="align-content-center">
                    <p>
                        <a href="https://www.jackalopeadventures.com">
                            jackalopeadventures.com &trade;
                        </a>
                    </p>
                    <SocialIcon url="https://www.facebook.com/jackalopeAdventureTouring" />
                    <SocialIcon url="https://www.instagram.com/jackalopeadventures/" />
                </Footer>
            </Container>
        </>
    );
}

const LayoutContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10rem;

    .hidden {
        display: none;
    }
`;

const MainContainer = styled.div`
    position: relative;
`;

const HeaderContainer = styled.div`
    position: relative;

    a {
        text-decoration: none;
        box-shadow: none;
        color: #2f4b72;
        display: block;

        img {
            max-height: 190px;
        }
    }

    .nav-link:hover {
        text-decoration: underline;
    }

    nav {
        height: 40px;
        justify-content: end;
    }

    @media (max-width: 768px) {
        .mobile-navbar {
            width: 100%;
            margin-top: 70px;
            margin-bottom: 40px;

            .container-fluid {
                padding: 4px;

                .navbar-collapse {
                    background-color: white;
                    width: 100%;
                    z-index: 4;

                    .navbar-nav {
                        background-color: white;

                        .header-link {
                            border-bottom: 1px solid black;
                            padding: 20px;
                        }
                        .header-link:hover {
                            background-color: blue;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 575px) {
        .mobile-navbar {
            margin-top: 10px;
            margin-bottom: 100px;

            .container-fluid {
                .navbar-collapse {
                    border: 2px solid black;
                }
            }
            .navbar-brand {
                img {
                    max-height: 90px;
                }
            }
        }
    }
`;

const Wrapper = styled.div`
    min-height: 100vh;
`;

const Footer = styled.footer`
    text-align: center;
    width: 100%;
    height: 2.5rem;

    a {
        box-shadow: none;
        margin-left: 10px;
        height: 30px !important;
        width: 30px !important;
    }
`;
