import React from "react";
import styled from "styled-components";
import HeroImage from "../../content/assets/superior_jack.png";

const Hero = (props) => {
  return (
    <div className="row d-none d-lg-block">
      <div className="m-auto w-100">
        <img src={HeroImage} />
      </div>
    </div>
  );
}

export default Hero;